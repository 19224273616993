import React from 'react';
import logo from './DRIEVU-logo.png'; // Replace with the actual path of your logo
import './App.css'; // Importing CSS for styling

function App() {
  return (
    <div className="App">
      <header className="App-header" fontColor="teal">
        <div className="animate-flicker">
        <img src={logo} className="App-logo" alt="logo" />
        </div>       
        <div className='animate-comingsoon'>
        <h1>
          <br />
          Coming Soon ...
        </h1>
        </div>

      </header>
    </div>
  );
}

export default App;
